/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InnerPageNavigation',
        import: () => import('@stories/Widgets/InnerPageNavigation/InnerPageNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileTabbedAreaDetails',
        import: () => import('@stories/Widgets/AccountArea/ProfileTabbedArea/ProfileTabbedAreaDetails/ProfileTabbedAreaDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ChangePassword',
        import: () => import('@stories/Widgets/AccountArea/ProfileTabbedArea/ChangePassword/ChangePassword'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileTabbedAreaCompanyDetails',
        import: () => import('@stories/Widgets/AccountArea/ProfileTabbedArea/ProfileTabbedAreaCompanyDetails/ProfileTabbedAreaCompanyDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileTabbedAreaManageEmployees',
        import: () => import('@stories/Widgets/AccountArea/ProfileTabbedArea/ProfileTabbedAreaManageEmployees/ProfileTabbedAreaManageEmployees'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomepageHeroBanner',
        import: () => import('@stories/Widgets/HomepageHeroBanner/HomepageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHeroBanner',
        import: () => import('@stories/Widgets/InnerPageHeroBanner/InnerPageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedContentSignpost',
        import: () => import('@stories/Widgets/TabbedContentSignpost/TabbedContentSignpost'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedUspContent',
        import: () => import('@stories/Widgets/TabbedUspContent/TabbedUspContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ReviewsCarousel',
        import: () => import('@stories/Widgets/ReviewsCarousel/ReviewsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MagazineCarousel',
        import: () => import('@stories/Widgets/MagazineCarousel/MagazineCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipBenefitCards',
        import: () => import('@stories/Widgets/MembershipBenefitCards/MembershipBenefitCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MemberList',
        import: () => import('@stories/Widgets/MemberList/MemberList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaEmbed',
        import: () => import('@stories/Widgets/MediaEmbed/MediaEmbed'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollSplitContent',
        import: () => import('@stories/Widgets/ScrollSplitContent/ScrollSplitContent'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SingleTestimonial',
        import: () => import('@stories/Widgets/SingleTestimonial/SingleTestimonial'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'VacancyList',
        import: () => import('@stories/Widgets/VacancyList/VacancyList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestNews',
        import: () => import('@stories/Widgets/LatestNews/LatestNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembersLounge',
        import: () => import('@stories/Widgets/MembersLounge/MembersLounge'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembersLoungeHeader',
        import: () => import('@stories/Components/VenueSignIn/Header/Header'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventSignIn',
        import: () => import('@stories/Widgets/EventSignIn/EventSignIn'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventSignInHeader',
        import: () => import('@stories/Components/EventSignIn/Header/Header'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CallToActionBanner',
        import: () => import('@stories/Widgets/CallToActionBanner/CallToActionBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventList',
        import: () => import('@stories/Widgets/EventList/EventList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatisticsList',
        import: () => import('@stories/Widgets/StatisticsList/StatisticsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostBanner',
        import: () => import('@stories/Widgets/SignpostBanner/SignpostBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GoogleMapEmbed',
        import: () => import('@stories/Widgets/GoogleMapEmbed/GoogleMapEmbed'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CalloutCards',
        import: () => import('@stories/Widgets/CalloutCards/CalloutCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InteractiveSubscriptionRatesTable',
        import: () => import('@stories/Widgets/InteractiveSubscriptionRatesTable/InteractiveSubscriptionRatesTable'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FAQs',
        import: () => import('@stories/Widgets/FAQs/FAQs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OffersListing',
        import: () => import('@stories/Widgets/OffersListing/OffersListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Gallery',
        import: () => import('@stories/Widgets/Gallery/Gallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleDetailBanner',
        import: () => import('@stories/Widgets/ArticleDetailBanner/ArticleDetailBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'KenticoForm',
        import: () => import('@stories/Widgets/KenticoForm/KenticoForm'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HtmlSiteMap',
        import: () => import('@stories/Widgets/HtmlSiteMap/HtmlSiteMap'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignupBanner',
        import: () => import('@stories/Widgets/SignupBanner/SignupBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentPill',
        import: () => import('@stories/Widgets/SplitContentPill/SplitContentPill'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedArticles',
        import: () => import('@stories/Widgets/RelatedArticles/RelatedArticles'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedPdf',
        import: () => import('@stories/Widgets/EmbeddedPdf/EmbeddedPdf'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleListing',
        import: () => import('@stories/Widgets/ArticleListing/ArticleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipBanner',
        import: () => import('@stories/Widgets/MembershipBanner/MembershipBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'UpcomingEventsCarousel',
        import: () => import('@stories/Widgets/UpcomingEventsCarousel/UpcomingEventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PastEventsCarousel',
        import: () => import('@stories/Widgets/PastEventsCarousel/PastEventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResults',
        import: () => import('@stories/Widgets/SearchResults/SearchResults'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventDetail',
        import: () => import('@stories/Widgets/EventDetail/EventDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCards',
        import: () => import('@stories/Widgets/CtaCards/CtaCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TextMedia',
        import: () => import('@stories/Widgets/TextMedia/TextMedia'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactCards',
        import: () => import('@stories/Widgets/ContactCards/ContactCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PolicyPublicationsCarousel',
        import: () => import('@stories/Widgets/PolicyPublicationsCarousel/PolicyPublicationsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PeopleListing',
        import: () => import('@stories/Widgets/PeopleListing/PeopleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountNavigation',
        import: () => import('@stories/Widgets/AccountArea/AccountNavigation/AccountNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'AccountOverview',
        import: () => import('@stories/Widgets/AccountArea/AccountOverview/AccountOverview'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'AccountWelcomeMessage',
        import: () => import('@stories/Widgets/AccountArea/AccountWelcomeMessage/AccountWelcomeMessage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileIntroduction',
        import: () => import('@stories/Widgets/AccountArea/ProfileIntroduction/ProfileIntroduction'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountCtaCards',
        import: () => import('@stories/Widgets/AccountArea/AccountCtaCards/AccountCtaCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountPeopleListing',
        import: () => import('@stories/Widgets/AccountArea/AccountPeopleListing/AccountPeopleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountMembershipBenefitCards',
        import: () => import('@stories/Widgets/AccountArea/AccountMembershipBenefitCards/AccountMembershipBenefitCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountUpgradeBanner',
        import: () => import('@stories/Widgets/AccountArea/AccountUpgradeBanner/AccountUpgradeBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembersDirectorySearch',
        import: () => import('@stories/Widgets/AccountArea/MembersDirectorySearch/MembersDirectorySearch'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'PremiumAccountNotificationBanner',
        import: () => import('@stories/Widgets/AccountArea/PremiumAccountNotificationBanner/PremiumAccountNotificationBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UnpaidTransactions',
        import: () => import('@stories/Widgets/AccountArea/UnpaidTransactions/UnpaidTransactions'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'CourseListing',
        import: () => import('@stories/Widgets/CourseListing/CourseListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipOptions',
        import: () => import('@stories/Widgets/MembershipOptions/MembershipOptions'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import('@stories/Widgets/Global/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UpcomingEventsCarousel',
        import: () => import('@stories/Widgets/UpcomingEventsCarousel/UpcomingEventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventPageHeroBanner',
        import: () => import('@stories/Widgets/EventPageHeroBanner/EventPageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NotificationBanner',
        import: () => import('@stories/Widgets/NotificationBanner/NotificationBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GlobalNotificationBanner',
        import: () => import('@stories/Widgets/GlobalNotificationBanner/GlobalNotificationBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UploadVenueForm',
        import: () => import('@stories/Widgets/AccountArea/UploadVenueForm/UploadVenueForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UploadEventForm',
        import: () => import('@stories/Widgets/AccountArea/UploadEventForm/UploadEventForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UploadOfferForm',
        import: () => import('@stories/Widgets/AccountArea/UploadOfferForm/UploadOfferForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CompleteProfileBanner',
        import: () => import('@stories/Components/AccountArea/CompleteProfileBanner/CompleteProfileBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RequestLogo',
        import: () => import('@stories/Widgets/AccountArea/RequestLogo/RequestLogo'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PublicationsListing',
        import: () => import('@stories/Widgets/PublicationsListing/PublicationsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MagazinesListing',
        import: () => import('@stories/Widgets/MagazinesListing/MagazinesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VenueCarousel',
        import: () => import('@stories/Widgets/VenueCarousel/VenueCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VenueList',
        import: () => import('@stories/Widgets/VenueList/VenueList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipSignupForm',
        import: () => import('@stories/Widgets/MembershipSignupForm/MembershipSignupForm'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ResetPassword',
        import: () => import('@stories/Widgets/ResetPassword/ResetPassword'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'MembersDirectoryListing',
        import: () => import('@stories/Widgets/AccountArea/MembersDirectoryListing/MembersDirectoryListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipDirectoryDetail',
        import: () => import('@stories/Widgets/AccountArea/MembershipDirectoryDetail/MembershipDirectoryDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CommunityNetworkSignupForm',
        import: () => import('@stories/Widgets/CommunityNetworkSignupForm/CommunityNetworkSignupForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RenewMembership',
        import: () => import('@stories/Widgets/AccountArea/RenewMembership/RenewMembership'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PriceGuide',
        import: () => import('@stories/Widgets/PriceGuide/PriceGuide'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SubscriptionRateTable',
        import: () => import('@stories/Widgets/SubscriptionRateTable/SubscriptionRateTable'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResults',
        import: () => import('@stories/Widgets/SearchResults/SearchResults'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventListing',
        import: () => import('@stories/Widgets/EventListing/EventListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BookEventForm',
        import: () => import('@stories/Widgets/BookEventForm/BookEventForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EditEventForm',
        import: () => import('@stories/Widgets/AccountArea/EditEventForm/EditEventForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ManageBookingsEventsCarousel',
        import: () => import('@stories/Widgets/AccountArea/ManageBookingsEventsCarousel/ManageBookingsEventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
];
